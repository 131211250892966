import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {useLocation} from '@reach/router';
import {usePageContent} from 'src/functionality/content-provider';

import Page from 'src/components/functional/page';
import {Container} from 'react-bootstrap';
import {theme} from 'styles/theme';
import PlainHeroSection from 'src/components/functional/hero-section-plain';
import placeholderImage from 'images/placeholder.jpg';
import {H3} from 'src/components/functional/headings';
import ContentBlock from 'src/components/functional/content-block';
import MoreAboutUs from 'src/components/functional/more-about-us-section';
import Tile from 'src/components/functional/tile';
import Spacer from 'src/components/graphical/spacer';

import {getResource} from 'src/functionality/resources-provider';

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  & blockquote{
    background-color: ${theme.bodyBg};
    border-left: 0.25em solid ${theme.primary};
  }
`;

const CenteredDocument = styled.div`
  display: flex;
  justify-content: center;
`;

const ComplaintsPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);

  return (
    <Page white="true">
      <PlainHeroSection
        backgroundColor={theme.mediumGray}
        headingSize="md"
        breadcrumbs="true"
        content={content}
        tag="CP01"
        bgImage={placeholderImage}
      />
      <Wrapper>
        <ContentBlock
          header={(props) => <H3 {...props}/>}
          content={content}
          tag='CP04'
        />
        <ContentBlock
          header={(props) => <H3 {...props}/>}
          content={content}
          tag='CP02'
        />
        <ContentBlock
          header={(props) => <H3 {...props}/>}
          content={content}
          tag='CP03'
        />
        <CenteredDocument>
          <Tile
            isRounded
            type='document'
            onClick={() =>
            // eslint-disable-next-line max-len
              getResource('resources/Policies-plans-and-procedures/Client-Complaints-Charter-of-Human-Rights.pdf')
                  .then((url) => window.open(url))}
            title='Charter of Human Rights'
          />
          <Tile
            isRounded
            type='document'
            onClick={() =>
            // eslint-disable-next-line max-len
              getResource('resources/Policies-plans-and-procedures/Complaints-Brochure-2020.pdf')
                  .then((url) => window.open(url))}
            title='Complaints Brochure'
          />
        </CenteredDocument>
        <Spacer height={4}/>
      </Wrapper>
      <MoreAboutUs />
    </Page>
  );
};

ComplaintsPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default ComplaintsPage;


