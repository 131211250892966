import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {theme} from 'styles/theme';
import document from 'src/images/document.svg';
import {Col} from 'react-bootstrap';
import {H6} from 'src/components/functional/headings';
import Markdown from 'react-markdown';

const Wrapper = styled(Col)`
  padding: 0.5em 1.25em;
  display: inline-block;
  & h6{
    margin-top: 0.25em;
    margin-bottom: 0;
  }
`;

const Document = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25em;
  
  background-color: ${theme.mediumGray};
  transition: all 0.15s ease-in-out;
  border-radius: ${(props) => props.isRounded ? '2em': '0'};
  
  &:hover{
    background-color: #D0D0D0;
    cursor: pointer;
  }
  
  & img{
    height: 100%;
    width: auto;
    box-shadow: 0px 0px 30px 0px #0000003A;
    transition: all 0.15s ease-in-out;
  }
  &:hover img{
    box-shadow: 0px 5px 45px 0px #0000003A;
  }
`;

const Picture = styled.div`
  height: 100%;
  border-radius: ${(props) => props.isRounded ? '2em': '0'};
  margin: 0;
  padding: 0;
  overflow: hidden;
  
  max-height: max(15em, 10vh);
  
  & img{
    height: 100%;
    width: 100%;
  }
`;

const Title = styled(H6)`
  text-align: ${(props) => props.centered ? 'center' : 'unset'};
`;

const Subtitle = styled.span`
  color: ${theme.primary};
  font-weight: bold;
  text-transform: uppercase;
  text-align: ${(props) => props.centered ? 'center' : 'unset'};
`;

const Textblock = styled.div`
`;

const Tile = (props) => {
  return (
    <Wrapper
      {...props}
      sm={8} md={6} lg={4} xl={3}
      className={props.className}
    >
      <Link
        to={props.to}
      >
        {props.type === 'document' &&
          <Document isRounded={props.isRounded}>
            <img src={document} alt={props.title} />
          </Document>
        }
        {props.type === 'picture' &&
          <Picture isRounded={props.isRounded}>
            <img src={props.src} alt={props.title} />
          </Picture>
        }
      </Link>
      <Title noUnderline={true} light={props.light} centered={props.centered}>
        {props.title}
      </Title>
      <Textblock>
        <Subtitle centered={props.centered}>
          {props.subtitle}
        </Subtitle>
        <Markdown>{props.children}</Markdown>
      </Textblock>
    </Wrapper>
  );
};

Tile.propTypes = {
  type: PropTypes.string.isRequired,
  isRounded: PropTypes.bool,
  src: PropTypes.string,
  light: PropTypes.bool,
  anchor: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  key: PropTypes.number,
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export default Tile;
