import React from 'react';
import {Link} from 'gatsby';
import {theme} from 'src/styles/theme';

import {Container, Row, Col} from 'react-bootstrap';
import Spacer from 'src/components/graphical/spacer';
import {H3} from 'src/components/functional/headings';
import styled from 'styled-components';

const MoreSection = styled.section`
  background-color: ${theme.darkGray};
  padding: 5em 0;
`;

const MoreColHeading = styled.h4`
  color: ${theme.primary};
  text-transform: uppercase;
  text-decoration: none;
`;

const Li = styled.li`
  padding: 0.25em 0;
`;

const MoreList = styled.ul`
  margin-top: 2em;
  text-transform: uppercase;
  padding: 0;
  & li a{
    color: ${theme.light};
    margin: 0.25em 0;
    letter-spacing: 0.1em;
    text-decoration: none;
  }
  & li a:hover{
    color: ${theme.mediumGray};
  }
`;

const links = [
  {
    title: 'People',
    links: [
      {
        text: 'Governance',
        href: '/about/people/management-and-advisors/#governance',
      }, {
        text: 'Working at arbias',
        href: '/about/people/working-at-arbias',
      }, // eslint-disable-next-line max-len
      {
        text: 'Executive Managers',
        href: '/about/people/management-and-advisors/#executive-managers',
      },
    ],
  },
  {
    title: 'What We Do',
    links: [
      {
        text: 'What we do',
        href: '/about/what-we-do/',
      }, {
        text: 'Training',
        href: '/about/training/',
      }, {
        text: 'Our Philosophy',
        href: '/about/our-philosophy/',
      },
    ],
  },
  {
    title: 'Information',
    links: [
      {
        text: 'News & Announcements',
        href: '/about/publications/',
      }, {
        text: 'Information Portal',
        href: '/information/',
      }, {
        text: 'For New Clients',
        href: '/information/#I07',
      }, {
        text: 'Getting A Referral',
        href: '/information/#I06',
      },
    ],
  },
];

const MoreAboutUs = (props) => {
  return (
    <MoreSection>
      <Container className="text-center">
        <H3 light={true}>More About Us</H3>
        <Spacer height={4}/>
        <nav>
          <Row>
            {links.map((section, sectionKey) => (
              <Col key={sectionKey}>
                <MoreColHeading>
                  {section.title}
                </MoreColHeading>
                <MoreList>
                  {section.links.map((link, listKey) =>
                    (link.href && link.text &&
                      <Li key={listKey}>
                        <Link to={link.href}>
                          {link.text}
                        </Link>
                      </Li>
                    ),
                  )}
                </MoreList>
              </Col>
            ))}
          </Row>
        </nav>
      </Container>
    </MoreSection>
  );
};


export default MoreAboutUs;
