import PropTypes from 'prop-types';
import React from 'react';
import {Container} from 'react-bootstrap';
import styled from 'styled-components';

import ContentBlock from 'src/components/functional/content-block';
import {H1} from 'src/components/functional/headings';
import {theme} from 'src/styles/theme';
import DynamicBreadcrumb from 'src/components/functional/dynamic-breadcrumb';

const Background = styled.div`
  min-height: 50vh;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-position: top middle;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Banner = styled.div`
  padding: 4vh 0;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : theme.light};
`;

const BreadcrumbContainer = styled(Container)`
  padding: 2vh 0;
  border-bottom: 1px solid ${theme.mediumGray};
`;

const PlainHeroSection = (props) => {
  const content = props.content;

  let HeadingSize;
  if (!props.headingSize) HeadingSize = 1;
  else if (props.headingSize === 'lg') HeadingSize = 2;
  else if (props.headingSize === 'md') HeadingSize = 3;
  else if (props.headingSize === 'sm') HeadingSize = 4;

  return (
    <section>
      <Background image={props.bgImage}>
        <Banner backgroundColor={props.backgroundColor}>
          <Container>
            <ContentBlock
              header={(props) =>
                <H1
                  className="text-primary"
                  display={HeadingSize}
                  light={true}
                  noUnderline
                  {...props}
                />}
              content={content}
              tag={props.tag}
              contentWrapper={(props) => <div className="lead" {...props}/>}
            />
          </Container>
        </Banner>
      </Background>
      <BreadcrumbContainer>
        {props.breadcrumbs &&
          <DynamicBreadcrumb />
        }
      </BreadcrumbContainer>
    </section>
  );
};

PlainHeroSection.propTypes = {
  bgImage: PropTypes.any,
  breadcrumbs: PropTypes.any,
  content: PropTypes.any,
  backgroundColor: PropTypes.string,
  headingSize: PropTypes.string,
  tag: PropTypes.string.isRequired,
};

export default PlainHeroSection;
