import {useState, useEffect} from 'react';
import {Storage} from 'aws-amplify';

/**
 * Returns a list of resources (files) from Amplify Storage
 * @param {string} directory optional path to folder to get resources from
 * @return {array} an array of objects in format {"title": '', "url": ''}
 */
export function useResources(directory) {
  const [resources, setResources] = useState();
  const [categories, setCategories] = useState();
  const path = directory ? `resources/${directory}/` : 'resources/';
  useEffect(() => {
    if (!resources) {
      Storage.list(path)
          .then((files) => {
            const formattedResources = [];
            const formattedCategories = [];
            files.forEach((file) => {
              if (file.size === 0) {
                const category = file.key.split('/')[1];
                if (category !== '') formattedCategories.push(category);
              } else {
                formattedResources.push({
                  title: file.key
                      .split('/').pop() // get only filename
                      .replace(/\.[^/.]+$/, '') // remove extension
                      .replace(/-/g, ' '), // replace '-' with spaces
                  category: file.key.split('/')[1],
                  url: file.key,
                  eTag: file.eTag,
                });
              }
            });
            setResources(formattedResources.sort(
                (a, b) => a.title.localeCompare(b.title)).reverse());
            setCategories(formattedCategories);
          })
          .catch((err) => console.error(err));
    }
  }, [resources, categories, path]);

  return [resources, categories];
}

/**
 * Returns a url of a given AWS Storage (S3) object by key.
 * @param {string} key The key of the requested storage object
 * @return {string} a url string
 */
export async function getResource(key) {
  return await Storage.get(key);
}
